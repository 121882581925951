<template>
    <div>
        <van-swipe class="my-swipe" :autoplay="2000" indicator-color="white">
            <van-swipe-item v-for="(item,i) in swipeList" :key="i">
                <div class="swipe-item">
                    <div v-for="items in item.list" :key="items.id" @click="goDetails(items)">
                        <img :src="items.logo" alt="">
                    </div>
                </div>
            </van-swipe-item>
        </van-swipe>
        <adContent></adContent>
        <div class="cont-nine">
            <div class="nine-card" v-for="item in nineList" :key="item.id" @click="goDetails(item)">
                <img :src="item.logo" alt="">
                <p class="no-warp">{{item.title}}</p>
            </div>
        </div>
        <div class="cont-st">
            <div class="cont-st-h">
                <p>{{firstList.title}}</p>
                <div @click="goselect(firstList.title)">more</div>
            </div>
            <div class="cont-st-card" v-for="item in firstList.games" :key="item.id" @click="goDetails(item)">
                <img :src="item.logo" alt="">
                <p class="no-warp">{{item.title}}</p>
                <van-rate
                v-model="value"
                readonly
                :size="10"
                color="#FF3434"
                void-icon="star"
                void-color="#eee"
                />
            </div>
        </div>
        <div class="cont-st cont-ed">
            <div class="cont-st-h">
                <p>Hot GAMES</p>
                <div @click="goselect('Hot')">more</div>
            </div>
            <div class="cont-ed-card" v-for="item in secondList.games" :key="item.id" @click="goDetails(item)">
                <div class="ed-card-l">
                    <img :src="item.logo" alt="">
                </div>
                <div class="ed-card-r">
                    <p class="no-warp">{{item.title}}</p>
                    <van-rate
                    v-model="item.rating"
                    readonly
                    :size="10"
                    color="#FF3434"
                    void-icon="star"
                    void-color="#eee"
                    />
                    <div class="play">PLAY</div>
                </div>
            </div>
        </div>
        <div class="cont-st cont-list" v-for="item in gameList" :key="item.title">
            <div class="cont-st-h">
                <p>{{item.title}}</p>
                <div @click="goselect(item.title)">more</div>
            </div>
            <div class="cont-list-card" v-for="items in item.games" :key="items.id" @click="goDetails(items)">
                <img :src="items.logo" alt="">
                <p class="no-warp">{{items.title}}</p>
            </div>
            <span v-for="item in spanNum" :key="item+'s'"></span>
        </div>
    </div>
</template>

<script>
import gemaData from '@/utils/gemedata/data'
import { getRandomNumbers, shuffleArray, isMobile, scrollToTop } from "@/utils/api"
import eventBus from '@/utils/eventBus'
import { getGameList, getGameByKey, getGame } from "@/api/games"
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent

        },
        data() {
            return {
                firstList:{
                    title:'',
                    games:[]
                },
                secondList:{
                    title:'',
                    games:[]
                },
                gameList:[],
                value:4,
                allList:[],
                gameListC:[],
                swipeList:[],
                nineList:[],
                spanNum:0
            }
        },
        mounted() {
            this.getList();
            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 11-2
            }
            let menukey = this.$route.params.menuKey;
            if(menukey){
                this.getMenuGame(menukey)
            }else{
                eventBus.$on('selectMenu',key=>{                    
                    this.getMenuGame(key)
                });
            }
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            async getList(){
                let gameList1 = []
                let gameList = gemaData.games;

                gameList.forEach(item=>{
                    gameList1.push(item.key)
                })

                gameList1 = Array.from(new Set(gameList1));
                
                gameList1.forEach((item,i)=>{
                    gameList1.splice(i,1,{
                        title:item,
                        games:[],
                        url:''
                    })
                })

                gameList.forEach(item=>{
                    gameList1.forEach(items=>{
                        if(item.key==items.title){
                            items.games.push(item)
                        }
                    })
                })

                gameList1.forEach(item=>{                    
                    item.games = shuffleArray(item.games)
                })
                let res = await getGameList();
                Object.keys(res).forEach(key=>{
                    this.gameList.push({
                        title: key,
                        games: res[key]
                    });
                })
                this.allList = gameList;

                let firstList = await getGame({count:9})
                this.firstList = {
                    title:this.gameList[0].title,
                    games:firstList
                }

                let secondList = await getGame({count:6})
                this.secondList = {
                    title:this.gameList[1].title,
                    games:secondList
                }
                
                this.gameListC = JSON.parse(JSON.stringify(this.gameList));
                let swipeNum = 2;
                if(!isMobile()){
                    swipeNum = 6;
                }
                for (let i = 0; i < 4; i++) {
                    let list = await getGame({count:swipeNum})
                    this.swipeList.push({list})           
                }

                let nineList = await getGame({count:9})
                this.nineList = nineList
                
            },
            getRandomGames(str,end,len){
                let gamelist = getRandomNumbers(str,end,len);

                gamelist.forEach((item,i)=>{
                    gamelist.splice(i,1,this.allList[item])
                })

                return gamelist;
            },
            async getMenuGame(name){
                let games = await getGameByKey({category:name})
                this.gameList = [{
                    title: name,
                    games
                }]

                eventBus.$emit('showmenu',0)
                eventBus.$emit('showfenlei',0)

                scrollToTop(1600)
            },
            goselect(item){
                this.$router.push({
                    path: '/selectGame',
                    query:{
                        id: 'more',
                        key: item
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
.my-swipe{
    margin-bottom: 28px;
    .swipe-item{
        padding: 12px 20px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        img{
            width: 165px;
            height: 165px;
            border-radius: 15px;
        }
    }
}
.cont-nine{
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .nine-card{
        width: 101px;
        border-radius: 15px;
        margin-bottom: 15px;
        img{
            border: 2px solid #FFFFFF;
            width: 101px;
            height: 101px;
            border-radius: 15px;
        }
        p{
            white-space: nowrap;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #1E1B4C;
            line-height: 20px;
            text-align: left;
        }
    }
}
.cont-st{
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .cont-st-h{
        width: 100%;
        height: 40px;
        background: #504BA9;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            text-transform: uppercase;
            margin-left: 12px;
        }
        div{
            margin-right: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            text-transform: uppercase;
        }
    }
    .cont-st-card{
        margin: 12px 0;
        padding-top: 6px;
        box-sizing: border-box;
        width: 106px;
        height: 152px;
        background: #FFFFFF;
        border-radius: 11px;
        text-align: center;
        img{
            width: 90px;
            height: 90px;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            line-height: 20px;
            font-style: normal;
        }
    }
}
.cont-ed{
    .cont-ed-card{
        width: 162px;
        height: 86px;
        padding: 5px;
        margin: 8px 0 0 0;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        .ed-card-l{
            width: 76px;
            height: 76px;
            border-radius: 10px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .ed-card-r{
            width: 70px;
            overflow: hidden;
            p{
                margin-bottom: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
            .play{
                margin: 0 auto;
                width: 63px;
                height: 21px;
                text-align: center;
                line-height: 21px;
                background: #504BA9;
                border-radius: 12px;

                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 9px;
                color: #FFFFFF;
                font-style: normal;
                text-transform: uppercase;
            }
        }
    }
}
.cont-list{
    // &::after{
    //     content: '   ';
    //     flex: auto;
    //     display: block;
    //     width: 100px;
    // }
    .cont-list-card{
        width: 100px;
        margin: 16px 0 0 0;
        img{
            width: 100px;
            height: 100px;
            border-radius: 15px;
            border: 2px solid #FFFFFF;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #1E1B4C;
            line-height: 20px;
            text-align: left;
            font-style: normal;
        }
    }
    span{
        width: 100px;
    }
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>